import { environment } from '../../../environments/environment';
import { StaticLookup } from '../model/extra/staticLookupModel';

export const ServiceUrls =
{
  dashboardGroup: {
    request: '/request',
    StrategyDashboard: {
      Dashboard: '/StrategyDashboard/Dashboard',
      GetByClassification: '/StrategyDashboard/GetByClassification',
      GetOverAll: '/StrategyDashboard/GetOverAll',
      GetByGroupNameAndClassification: '/StrategyDashboard/GetByGroupNameAndClassification',
      GetKpiCount: '/StrategyDashboard/GetKpiCount',
      GetAssignedTo: '/StrategyDashboard/GetAssignedTo',
      Years: '/StrategyDashboard/Years'
    }
  },
  strategyGroup: {
    Strategy: {
      GetAll: '/Strategy/GetAll',
      AddNewStrategyPlan: '/Strategy/AddNewStrategyPlan',
      UpdateStrategyPlan: '/Strategy/UpdateStrategyPlan',
      DeleteStrategyPlan: '/Strategy/DeleteStrategyPlan',
      UpdateStrategyPlanDetails: '/Strategy/UpdateStrategyPlanDetails',
      UploadStrategyFiles: environment.apiUrl + '/Strategy/UploadStrategyFiles'
    },
    StrategyGoal: '/StrategyGoal',
    SWOTSection: {
      GetSWOTAll: '/SWOTSection/GetSWOTAll',
      Create: '/SWOTSection/Create',
      Update: '/SWOTSection/Update',
      Delete: '/SWOTSection/Delete'
    },
    SWOTResource: '/SWOTResource',
    Aim: '/Aim',
    BalancedScorecard: '/BalancedScorecard'
  },
  PerformanceManagement: {
    GetAimCount: '/PerformanceManagement/GetAimCount',
    GetAims: '/PerformanceManagement/GetAims',
    GetStrategyGoalCount: '/PerformanceManagement/GetStrategyGoalCount',
    GetStrategyGoals: '/PerformanceManagement/GetStrategyGoals',
    GetSectorGoalCount: '/PerformanceManagement/GetSectorGoalCount',
    GetSectorGoals: '/PerformanceManagement/GetSectorGoals',
    GetDepartmentGoalCount: '/PerformanceManagement/GetDepartmentGoalCount',
    GetDepartmentGoals: '/PerformanceManagement/GetDepartmentGoals',
    GetSectorGoalList: '/PerformanceManagement/GetSectorGoalList',
    GetSectorGoalListWithOnDetails: '/PerformanceManagement/GetSectorGoalListWithOnDetails',
    GetDepartmentGoalListWithOnDetails: '/PerformanceManagement/GetDepartmentGoalListWithOnDetails',
  },
  performanceManagementGroup: {
    request: '/request',
    Sector: '/sector',
    SectorGoal: '/SectorGoal',
    SectorSWOT: '/SWOTSection',
    StrategyGoal: '/StrategyGoal',
    BalancedScorecard: '/BalancedScorecard',
    Department: '/Department',
    DepartmentGoal: '/DepartmentGoal',
    AggregationMethod: '/AggregationMethod',
    CalculationPeriod: '/CalculationPeriod',
    ValueType: '/ValueType',
    Classification: '/Classification',
    Status: '/Status',
    Trend: '/Trend',
    ColorValue: '/ColorValue',
    EquationKpi: '/EquationKpi',
    Subclassification: '/Subclassification',
    KpiGeneric: {
      AddKpiGeneric: '/KpiGeneric/Add',
      UpdateKpiGeneric: '/KpiGeneric/Update',
      DeleteKpiGeneric: '/KpiGeneric/Delete',
      GetAllKpiGeneric: '/KpiGeneric/GetAll',
      GetKpiNotAssigned: '/KpiGeneric/GetKpiNotAssigned',
      GetKpiAssigned: '/KpiGeneric/GetKpiAssigned',
      GetKpiAssignedMultiple: '/KpiGeneric/GetKpiAssignedMultiple',
      GetKpiContainsData: '/KpiGeneric/GetKpiContainsData',
      GetHierarchy: '/KpiGeneric/GetHierarchy',
      GetCount: '/KpiGeneric/GetCount',
      GetParents: '/KpiGeneric/GetParents',
      GetKpiAssignedApproved: '/KpiGeneric/GetKpiAssignedApproved',
      CheckIfKpiExists: '/KpiGeneric/CheckIfKpiExists',
      GetKpisNotSentToVerification: '/KpiGeneric/GetKpisNotSentToVerification',
      GetKpisByMissionId: '/KpiGeneric/GetKpisByMissionId',
      GetKpiGenericWithFullName: '/KpiGeneric/GetKpiGenericWithFullName',
      GetKpisNotAssignedFromLastYear: '/KpiGeneric/GetKpisNotAssignedFromLastYear',
      AssignKpisAssignedFromLastYear: '/KpiGeneric/AssignKpisAssignedFromLastYear',
      CanDeleteKpiAssign: '/KpiGeneric/CanDeleteKpiAssign',
      GetKpiGenericSharedCount: '/KpiGeneric/GetKpiGenericSharedCount',
      GetKpiGenericShared: '/KpiGeneric/GetKpiGenericShared',
      CanDeleteKpiGeneric: '/KpiGeneric/CanDeleteKpiGeneric',
      GetKpisNotAssignedOnTeamCount: '/KpiGeneric/GetKpisNotAssignedOnTeamCount',
      GetKpisNotAssignedOnTeam: '/KpiGeneric/GetKpisNotAssignedOnTeam',
    },
    KpiAssignEquation: {
      AddEquation: '/KpiAssignEquation/AddEquation',
      CheckIfEquationAdded: '/KpiAssignEquation/CheckIfEquationAdded',
      GetKpiAssignEquation: '/KpiAssignEquation/GetKpiAssignEquation',
      TrashKpiAssignEquation: '/KpiAssignEquation/TrashKpiAssignEquation'
    },
    // Kpi: {
    //     AddKpi: '/Kpi/AddKpi',
    //     UpdateKpi: '/Kpi/UpdateKpi',
    //     DeleteKpi: '/Kpi/DeleteKpi',
    //     KpiGetAll: '/Kpi/GetAll',
    //     KpiNotAssigned: '/Kpi/GetNotAssigned',
    //     KpiAssigned: '/Kpi/GetAssigned',
    //     GetByClassification: '/Kpi/GetByClassification'
    // },
    KpiAssign: {
      Get: '/KpiAssign/Get',
      Create: '/KpiAssign/Create',
      Update: '/KpiAssign/Update',
      UpdateSharedKpi: '/KpiAssign/UpdateSharedKpi',
      Delete: '/KpiAssign/Delete',
      ApproveQuarter: '/KpiAssign/ApproveQuarter',
      GetKpiAssignForStrategicMapByFilters: '/KpiAssign/GetKpiAssignForStrategicMapByFilters',
      ToggleIsCalculated: '/KpiAssign/ToggleIsCalculated',
      GetKpiAssignWithFiltersCount: '/KpiAssign/GetKpiAssignWithFiltersCount',
      GetKpiAssignWithFilters: '/KpiAssign/GetKpiAssignWithFilters',
      GetKpiAssignWithKpiGenericTree: '/KpiAssign/GetKpiAssignWithKpiGenericTree',
      GetKpiAssignById: '/KpiAssign/GetKpiAssignById',
      GetAllKpiAssignWithName: '/KpiAssign/GetAllKpiAssignWithName',
      GetMostFrequentKpiAssignData: '/KpiAssign/GetMostFrequentKpiAssignData',
      GetSectorsNotAssignedForSharedKpi: '/KpiAssign/GetSectorsNotAssignedForSharedKpi',
      GetDepartmentsNotAssignedForSharedKpi: '/KpiAssign/GetDepartmentsNotAssignedForSharedKpi',
      GetSectionsNotAssignedForSharedKpi: '/KpiAssign/GetSectionsNotAssignedForSharedKpi',
      GetKpiAssignNotAssignedOnStreamCount: '/KpiAssign/GetKpiAssignNotAssignedOnStreamCount',
      GetKpiAssignNotAssignedOnStream: '/KpiAssign/GetKpiAssignNotAssignedOnStream',
      GetKpiAssignedOnStreamCount: '/KpiAssign/GetKpiAssignedOnStreamCount',
      GetKpiAssignedOnStream: '/KpiAssign/GetKpiAssignedOnStream',
      GetKpiAssignNotAssignedInPerformanceAgreementCount: '/KpiAssign/GetKpiAssignNotAssignedInPerformanceAgreementCount',
      GetKpiAssignNotAssignedInPerformanceAgreement: '/KpiAssign/GetKpiAssignNotAssignedInPerformanceAgreement',
      GetKpiAssignAssignedInPerformanceAgreementCount: '/KpiAssign/GetKpiAssignAssignedInPerformanceAgreementCount',
      GetKpiAssignAssignedInPerformanceAgreement: '/KpiAssign/GetKpiAssignAssignedInPerformanceAgreement',
      AssignKpiInPerformanceAgreement: '/KpiAssign/AssignKpiInPerformanceAgreement',
      RemoveAssignKpiInPerformanceAgreement: '/KpiAssign/RemoveAssignKpiInPerformanceAgreement',
      GetKpiAssignViewById: '/KpiAssign/GetKpiAssignViewById',
      GetTotalKpiAssignedOnStreamsCount: '/KpiAssign/GetTotalKpiAssignedOnStreamsCount',
      GetStreamsTotalResult: '/KpiAssign/GetStreamsTotalResult',
      GetKpiAssignCountGroupOnCalculationPeriod: '/KpiAssign/GetKpiAssignCountGroupOnCalculationPeriod',
      GetKpiAssignNotAssignedOnAbilityCount: '/KpiAssign/GetKpiAssignNotAssignedOnAbilityCount',
      GetKpiAssignNotAssignedOnAbility: '/KpiAssign/GetKpiAssignNotAssignedOnAbility',
      GetKpiAssignedOnAbilityCount: '/KpiAssign/GetKpiAssignedOnAbilityCount',
      GetKpiAssignedOnAbility: '/KpiAssign/GetKpiAssignedOnAbility',
      GetKpiAssignNotAssignedOnProjectCount: '/KpiAssign/GetKpiAssignNotAssignedOnProjectCount',
      GetKpiAssignNotAssignedOnProject: '/KpiAssign/GetKpiAssignNotAssignedOnProject',
      GetKpiAssignedOnProjectCount: '/KpiAssign/GetKpiAssignedOnProjectCount',
      GetKpiAssignedOnProject: '/KpiAssign/GetKpiAssignedOnProject'
    },
    KpiAssignDataDetails: {
      GetAll: '/KpiAssignDataDetails/GetAll',
      GetWithKpiAssignId: '/KpiAssignDataDetails/GetWithKpiAssignId',
      GetWithId: '/KpiAssignDataDetails/GetWithId',
      UpdateDataDetails: '/KpiAssignDataDetails/UpdateDataDetails',
      UpdateDataDetailsSharedKpi: '/KpiAssignDataDetails/UpdateDataDetailsSharedKpi',
      UpdateSent: '/KpiAssignDataDetails/UpdateSent',
      CreateNewDataDetailsAndDeleteOld: '/KpiAssignDataDetails/CreateNewDataDetailsAndDeleteOld',
      CreateNewDataDetailsAndDeleteOldSharedKpi: '/KpiAssignDataDetails/CreateNewDataDetailsAndDeleteOldSharedKpi',
      DeleteFiles: '/KpiAssignDataDetails/DeleteFiles',
      DeleteById: '/KpiAssignDataDetails/DeleteById',
      TrashUpload: '/KpiAssignDataDetails/TrashUpload'
    },
    KpiSettings: {
      GetByCode: '/KpiSettings/GetByCode',
      UpdateByCode: '/KpiSettings/UpdateByCode'
    },
    KpiCalculationMethod: {
      GetAll: '/KpiCalculationMethod/GetAll'
    },
    Stream: {
      GetById: '/Stream/GetById',
      GetStreamsCount: '/Stream/GetStreamsCount',
      GetStreams: '/Stream/GetStreams',
      Add: '/Stream/Add',
      Update: '/Stream/Update',
      Trash: '/Stream/Trash',
      AssignKpiToStream: '/Stream/AssignKpiToStream',
      RemoveAssignKpiToStream: '/Stream/RemoveAssignKpiToStream',
      GetStreamsForDashboard: '/Stream/GetStreamsForDashboard',
    },
    SectionKpi: '/SectionKpi',
    Section: '/Section',
    KpiAssignTeam: {
      AssignKpisOnTeam: '/KpiAssignTeam/AssignKpisOnTeam',
      GetKpiAssignedOnTeamsAdminCount: '/KpiAssignTeam/GetKpiAssignedOnTeamsAdminCount',
      GetKpiAssignedOnTeamsAdmin: '/KpiAssignTeam/GetKpiAssignedOnTeamsAdmin',
      ToggleIsCalculated: '/KpiAssignTeam/ToggleIsCalculated',
      RemoveKpiAssignTeam: '/KpiAssignTeam/RemoveKpiAssignTeam',
      GetKpiAssignTeamById: '/KpiAssignTeam/GetKpiAssignTeamById',
      GetKpiAssignedOnTeamWaitingToFillMainDetailsByTeamManagerIdCount: '/KpiAssignTeam/GetKpiAssignedOnTeamWaitingToFillMainDetailsByTeamManagerIdCount',
      GetKpiAssignedOnTeamWaitingToFillMainDetailsByTeamManagerId: '/KpiAssignTeam/GetKpiAssignedOnTeamWaitingToFillMainDetailsByTeamManagerId',
      UpdateKpiAssignTeam: '/KpiAssignTeam/UpdateKpiAssignTeam',
      UpdateKpiAssignTeamWithNewQuarters: '/KpiAssignTeam/UpdateKpiAssignTeamWithNewQuarters',
      UpdateKpiAssignTeamAndSendToApproval: '/KpiAssignTeam/UpdateKpiAssignTeamAndSendToApproval',
      GetKpisInApprovalCount: '/KpiAssignTeam/GetKpisInApprovalCount',
      GetKpisInApproval: '/KpiAssignTeam/GetKpisInApproval',
      GetKpisPoolApprovalCount: '/KpiAssignTeam/GetKpisPoolApprovalCount',
      GetKpisPoolApproval: '/KpiAssignTeam/GetKpisPoolApproval',
      UpdateKpiAssignTeamAndApproveTargets: '/KpiAssignTeam/UpdateKpiAssignTeamAndApproveTargets',
      RejectKpiAssignTeamTargets: '/KpiAssignTeam/RejectKpiAssignTeamTargets',
    }
  },
  ProjectManagementGroup: {
    ProjectConfiguration: {
      GetAll: '/ProjectConfiguration/GetAll',
      GetByType: '/ProjectConfiguration/GetByType',
      Add: '/ProjectConfiguration/Add',
      Update: '/ProjectConfiguration/Update',
      ApproveNewExternalPartner: '/ProjectConfiguration/ApproveNewExternalPartner'
    },
    Program: '/Program',
    Project: {
      GetProjectById: '/Project/GetProjectById',
      GetProjectsWaitingPoolApproval: '/Project/GetProjectsWaitingPoolApproval',
      GetTasksWaitingPoolApproval: '/Project/GetTasksWaitingPoolApproval',
      GetProjectsDoneWaitingPoolApproval: '/Project/GetProjectsDoneWaitingPoolApproval',
      GetProjectsChangeRequest: '/Project/GetProjectsChangeRequest',
      GetAll: "/Project/GetAll",
      Add: "/Project/Add",
      AddProjectTask: "/Project/AddProjectTask",
      GetProjectsWaitingTeamTaskAssignment: "/Project/GetProjectsWaitingTeamTaskAssignment",
      UpdateProjectInApproval: "/Project/UpdateProjectInApproval",
      UpdateProjectTeamTask: "/Project/UpdateProjectTeamTask",
      Approve: "/Project/Approve",
      Reject: "/Project/Reject",
      AddSuggestion: "/Project/AddSuggestion",
      UpdateActulDate: "/Project/UpdateActulDate",
      UpdateBudgetSpent: "/Project/UpdateBudgetSpent",
      GetProjectApprovalCycleAssignId: "/Project/GetProjectApprovalCycleAssignId",
      GetProjectsWithCondition: "/Project/GetProjectsWithCondition",
      GetProjectsCountWithCondition: "/Project/GetProjectsCountWithCondition",
      AddProjectLearningAndChallengingPoints: "/Project/AddProjectLearningAndChallengingPoints",
      TrashProject: "/Project/TrashProject",
      UpdateProjectStrategyGoal: "/Project/UpdateProjectStrategyGoal",
      AssignKpiToProject: "/Project/AssignKpiToProject",
      RemoveAssignKpiToProject: "/Project/RemoveAssignKpiToProject",
      ChangeRequest: "/Project/ChangeRequest",
      MiniDashboard: {
        // Top Cards
        TaskCommitmentAchievement: "/Project/TaskCommitmentAchievement",
        ProjectCommitmentAchievement: "/Project/ProjectCommitmentAchievement",
        ProjectCommitmentFinish: "/Project/ProjectCommitmentFinish",
        BudgetCommitment: "/Project/BudgetCommitment",
        ProjectEfficiency: "/Project/ProjectEfficiency",
        GetProjectsDataTable: "/Project/GetProjectsDataTable",
        // For Sector
        SectorData: "/Project/SectorData",
        // For Department
        DepartmentData: "/Project/DepartmentData",
        // For Section
        SectionData: "/Project/SectionData",
      },
      GetProjectKpisCount: "/Project/GetProjectKpisCount",
      GetProjectKpis: "/Project/GetProjectKpis",
      TraskKpi: "/Project/TraskKpi",
      AddKpi: "/Project/AddKpi",
    },
    ProjectImpact: {
      UpdateOnSend: "/ProjectImpact/UpdateOnSend",
      GetProjectImpactPoolApproval: "/ProjectImpact/GetProjectImpactPoolApproval"
    },
    Task: {
      SetTaskDone: "/ProjectTask/SetTaskDone",
      GetTasksByProjectId: "/ProjectTask/GetTasksByProjectId",
      GetLastTaskNotDone: "/ProjectTask/GetLastTaskNotDone",
      SaveTaskBudget: "/ProjectTask/SaveTaskBudget"
    },
    ProjectFile: {
      GetTaskBudgetFiles: "/ProjectFile/GetTaskBudgetFiles",
      GetProjectFiles: "/ProjectFile/GetProjectFiles",
      GetFilesWithParms: "/ProjectFile/GetFilesWithParms",
      UploadTaskBudgetEvidence: environment.apiUrl + "/ProjectFile/UploadTaskBudgetEvidence",
      UploadReleaseEvidence: environment.apiUrl + "/ProjectFile/UploadReleaseEvidence",
      UploadProjectImpactActualValueEvidence: "/ProjectFile/UploadProjectImpactActualValueEvidence",
      TrashFile: "/ProjectFile/TrashFile",
    }
  },
  adminGroup: {
    Organization: '/organization',
    Sector: '/sector',
    Department: '/department',
    Section: '/section',
    Employee: '/employee',
    Mission: '/Mission',
    SectionKpi: '/sectionKpi',
    AggregationMethod: '/aggregationMethod',
    CalculationPeriod: '/calculationPeriod',
    ValueType: '/valueType',
    User: '/user',
    Position: '/position',
    Page: '/page',
    Group: '/Group',
    ApprovalCycle: '/ApprovalCycle',
    ApprovalCycleAssign: '/ApprovalCycleAssign',
    ApprovalCycleAssignStep: '/ApprovalCycleAssignStep',
    ApprovalNote: '/ApprovalNote',
    LinkedCategory: '/LinkedCategory',
    NoteParent: '/NoteParent',
    MapUser: '/MapUser',
    KpiGenericAdd: '/KpiGenericAdd',
    SystemDevelopmentTask: '/SystemDevelopmentTask',
    CorrectiveAction: {
      Create: '/CorrectiveAction/Create',
      GetByKpiAssignDataDetailId: '/CorrectiveAction/GetByKpiAssignDataDetailId'
    },
    Hierarchy: {
      GetDepartmentsByParentId: '/Hierarchy/GetDepartmentsByParentId',
      GetSectionsByParentId: '/Hierarchy/GetSectionsByParentId',
      GetSectorCount: '/Hierarchy/GetSectorCount',
      GetSectors: '/Hierarchy/GetSectors',
      GetSectorList: '/Hierarchy/GetSectorList',
      AddSector: '/Hierarchy/AddSector',
      UpdateSector: '/Hierarchy/UpdateSector',
      TrashSector: '/Hierarchy/TrashSector',
      GetDepartmentCount: '/Hierarchy/GetDepartmentCount',
      GetDepartments: '/Hierarchy/GetDepartments',
      GetDepartmentList: '/Hierarchy/GetDepartmentList',
      AddDepartment: '/Hierarchy/AddDepartment',
      UpdateDepartment: '/Hierarchy/UpdateDepartment',
      TrashDepartment: '/Hierarchy/TrashDepartment',
      GetSectionCount: '/Hierarchy/GetSectionCount',
      GetSections: '/Hierarchy/GetSections',
      GetSectionList: '/Hierarchy/GetSectionList',
      AddSection: '/Hierarchy/AddSection',
      UpdateSection: '/Hierarchy/UpdateSection',
      TrashSection: '/Hierarchy/TrashSection',
      GetHierarchyTree: '/Hierarchy/GetHierarchyTree',
    },
    UserV2: {
      GetCount: "/UserV2/GetCount",
      Get: "/UserV2/Get",
      GetList: "/UserV2/GetList",
      GetUserById: "/UserV2/GetUserById",
      Add: "/UserV2/Add",
      Update: "/UserV2/Update",
      Trash: "/UserV2/Trash",
      GetUserFromMapUser: "/UserV2/GetUserFromMapUser",
      ToggleStatus: "/UserV2/ToggleStatus",
      GetTeamMemberUsers: "/UserV2/GetTeamMemberUsers",
      UpdateCurrentHierarchyDetails: "/UserV2/UpdateCurrentHierarchyDetails"
    },
    MissionV2: {
      GetCount: "/MissionV2/GetCount",
      Get: "/MissionV2/Get",
      Add: "/MissionV2/Add",
      Update: "/MissionV2/Update",
      Trash: "/MissionV2/Trash",
      ToggleStatus: "/MissionV2/ToggleStatus",
    },
    HierarchyVersion: {
      Get: '/HierarchyVersion/Get',
      GetCount: '/HierarchyVersion/GetCount',
      GetList: '/HierarchyVersion/GetList',
      GetHierarchyVersionById: '/HierarchyVersion/GetHierarchyVersionById',
      Add: '/HierarchyVersion/Add',
      Update: '/HierarchyVersion/Update',
      Trash: '/HierarchyVersion/Trash',
      GetHierarchyOnDetailTree: '/HierarchyVersion/GetHierarchyOnDetailTree'
    },
    QuickAccessMenu: {
      GetQuickAccessMenuItems: '/QuickAccessMenu/GetQuickAccessMenuItems',
      GetTotalPending: '/QuickAccessMenu/GetTotalPending'
    }

  },
  excelleneceKpiGroup: {
    Axis: '/Axis',
    MainStandard: '/MainStandard',
    SubStandard: '/SubStandard',
    Ability: {
      GetAbilityById: '/Ability/GetAbilityById',
      GetAbilityCount: '/Ability/GetAbilityCount',
      GetAbilities: '/Ability/GetAbilities',
      AddAbility: '/Ability/AddAbility',
      UpdateAbility: '/Ability/UpdateAbility',
      TrashAbility: '/Ability/TrashAbility',
      GetAbilitiesBySubStandardAndYear: '/Ability/GetAbilitiesBySubStandardAndYear',
      AssignKpiToAbility: '/Ability/AssignKpiToAbility',
      RemoveAssignKpiToAbility: '/Ability/RemoveAssignKpiToAbility',
      GetAbilitiesByKpiAssignId: '/Ability/GetAbilitiesByKpiAssignId'
    },
    ExcellenceFile: {
      UploadExcellenceFile: environment.apiUrl + '/ExcellenceFile/UploadExcellenceFile',
      TrashFile: '/ExcellenceFile/TrashFile'
    },
    ExcellenceView: {
      GetKpisAssignedToStandardCount: '/ExcellenceView/GetKpisAssignedToStandardCount',
      GetKpisAssignedToStandard: '/ExcellenceView/GetKpisAssignedToStandard',
      GetExcellenceKpiTrend: '/ExcellenceView/GetExcellenceKpiTrend',
      GetKpisAssignedToAbilityCount: '/ExcellenceView/GetKpisAssignedToAbilityCount',
      GetKpisAssignedToAbility: '/ExcellenceView/GetKpisAssignedToAbility',
    },
    ExcellenceMain: {
      GetMainStandardCount: '/ExcellenceMain/GetMainStandardCount',
      GetMainStandards: '/ExcellenceMain/GetMainStandards',
      GetSubStandardCount: '/ExcellenceMain/GetSubStandardCount',
      GetSubStandards: '/ExcellenceMain/GetSubStandards',
      CreateCompositeKpi: '/ExcellenceMain/CreateCompositeKpi',
      UpdateCompositeKpi: '/ExcellenceMain/UpdateCompositeKpi',
      DeleteCompositeKpi: '/ExcellenceMain/DeleteCompositeKpi',
      GetCompositeKpiCount: '/ExcellenceMain/GetCompositeKpiCount',
      GetCompositeKpis: '/ExcellenceMain/GetCompositeKpis',
      GetAxisCount: '/ExcellenceMain/GetAxisCount',
      GetAxes: '/ExcellenceMain/GetAxes',
      GetAxesForMainPage: '/ExcellenceMain/GetAxesForMainPage',
      UpdateExcellenceWeight: '/ExcellenceMain/UpdateExcellenceWeight',
      GetAxesByYear: '/ExcellenceMain/GetAxesByYear',
      GetMainStandardsByYear: '/ExcellenceMain/GetMainStandardsByYear',
      GetSubStandardsByYear: '/ExcellenceMain/GetSubStandardsByYear',
      GetAbilitiesByYear: '/ExcellenceMain/GetAbilitiesByYear',
      LoadExcellenceWeightTree: '/ExcellenceMain/LoadExcellenceWeightTree',
    }
  },
  KpiAssignStrategyGoal: {
    GetAll: '/KpiAssignStrategyGoal/GetAll',
    GetKpiFullNameAssigned: '/KpiAssignStrategyGoal/GetKpiFullNameAssigned',
    Update: '/KpiAssignStrategyGoal/Update',
    GetKpiAssignList: '/KpiAssignStrategyGoal/GetKpiAssignList'
  },
  KpiAssignSectorGoal: {
    GetAll: '/KpiAssignSectorGoal/GetAll',
    Update: '/KpiAssignSectorGoal/Update',
    GetKpiAssignList: '/KpiAssignSectorGoal/GetKpiAssignList',
  },
  approvalCycle: {
    Pool: {
      GetByPoolUserId: '/Pool/GetByPoolUserId',
      GetMissionsWithStatus: '/Pool/GetMissionsWithStatus',
      GetApprovalCycleAssignApproved: '/Pool/GetApprovalCycleAssignApproved',
      GetApprovalCycleAssignOnWaitingStep: '/Pool/GetApprovalCycleAssignOnWaitingStep',
      GetSectionsWithNumberOfKpis: '/Pool/GetSectionsWithNumberOfKpis',
      GetSectionsWithActualResultsVerification: '/Pool/GetSectionsWithActualResultsVerification',
      DeleteKpiAssignById: '/Pool/DeleteKpiAssignById',
      GetKpisWaitingInitiator: '/Pool/GetKpisWaitingInitiator',
      GetKpisWaitingToFillActualValues: '/Pool/GetKpisWaitingToFillActualValues',
      GetApprovalCycleAssignLastApproved: '/Pool/GetApprovalCycleAssignLastApproved',
      ApprovalCycleAssignRemoveApprove: '/Pool/ApprovalCycleAssignRemoveApprove',
      GetKpiStatisticsByCase: '/Pool/GetKpiStatisticsByCase',
      GetApprovalCycleAssignPendingCount: '/Pool/GetApprovalCycleAssignPendingCount',
      GetApprovalCycleAssignPending: '/Pool/GetApprovalCycleAssignPending',
      GetApprovalCycleAssignPendingByApprovalCycleCount: '/Pool/GetApprovalCycleAssignPendingByApprovalCycleCount'
    },
    TargetReason: {
      GetTargetReasons: '/TargetReason/GetTargetReasons',
      AddReason: '/TargetReason/AddReason',
      UpdateReason: '/TargetReason/UpdateReason',
      UploadFile: environment.apiUrl + '/TargetReason/UploadFile',
      TrashFile: '/TargetReason/TrashFile',
      TrashReason: '/TargetReason/TrashReason',
      AddAndUpdateTargetReasons: '/TargetReason/AddAndUpdateTargetReasons',
    }
  },
  Extra: {
    GetDepartmentGoalWithNumberUsage: '/Extra/GetDepartmentGoalWithNumberUsage',
    GetKpisAssignedToDepartmentObjective: '/Extra/GetKpisAssignedToDepartmentObjective',
    GetSectorGoalWithNumberUsage: '/Extra/GetSectorGoalWithNumberUsage',
    GetStrategyGoalWithNumberUsage: '/Extra/GetStrategyGoalWithNumberUsage',
    GetDepartmentObjectivesAssignedToSectorObjective: '/Extra/GetDepartmentObjectivesAssignedToSectorObjective',
    GetTotalKpisAverage: '/Extra/GetTotalKpisAverage',
    DeleteApprovalCycle: '/Extra/DeleteApprovalCycle',
    GetKpiAssignedWithKpiGenericId: '/Extra/GetKpiAssignedWithKpiGenericId',
    DeleteApprovalCycleAssign: '/Extra/DeleteApprovalCycleAssign',
    DeleteKpiAssignById: '/Extra/DeleteKpiAssignById',
    UpdateMissionKpiGeneric: '/Extra/UpdateMissionKpiGeneric',
    RemoveMissionKpiGeneric: '/Extra/RemoveMissionKpiGeneric',
    GetByFilterKpiAssign: '/Extra/GetByFilterKpiAssign',
    GetStrategyGoalsWithKpiCount: '/Extra/GetStrategyGoalsWithKpiCount',
    GetSectorGoalsWithKpiCount: '/Extra/GetSectorGoalsWithKpiCount',
    GetDepartmentGoalsWithKpiCount: '/Extra/GetDepartmentGoalsWithKpiCount',
    GetBalancedScorecardsWithKpiCount: '/Extra/GetBalancedScorecardsWithKpiCount',
    GetSectorsWithResult: '/Extra/GetSectorsWithResult',
    GetDepartmentsWithResult: '/Extra/GetDepartmentsWithResult',
    GetSectionsWithResult: '/Extra/GetSectionsWithResult',
    GetBalancedScorecardWithKpiCountApproved: '/Extra/GetBalancedScorecardWithKpiCountApproved',
    GetTargetAchievementPercentageBySector: '/Extra/GetTargetAchievementPercentageBySector',
    GetTargetAchievementPercentageByDepartment: '/Extra/GetTargetAchievementPercentageByDepartment',
    GetTargetAchievementPercentageBySection: '/Extra/GetTargetAchievementPercentageBySection',
    GetParticipationBySector: '/Extra/GetParticipationBySector',
    GetParticipationByDepartment: '/Extra/GetParticipationByDepartment',
    GetParticipationBySection: '/Extra/GetParticipationBySection',
    GetKpiAssignWithParents: '/Extra/GetKpiAssignWithParents',
    GetKpiAssignWithYear: '/Extra/GetKpiAssignWithYear',
    GetStrategyGoalWithKpiCountApproved: '/Extra/GetStrategyGoalWithKpiCountApproved',
    GetSectorsWithKpiDataQuality: '/Extra/GetSectorsWithKpiDataQuality',
    GetDepartmentsWithKpiDataQuality: '/Extra/GetDepartmentsWithKpiDataQuality',
    GetSectionsWithKpiDataQuality: '/Extra/GetSectionsWithKpiDataQuality',
    GetSectorsWithOnStep: '/Extra/GetSectorsWithOnStep',
    GetDepartmentsWithOnStep: '/Extra/GetDepartmentsWithOnStep',
    GetSectionsWithOnStep: '/Extra/GetSectionsWithOnStep',
    ExportExcel: '/Extra/ExportExcel',
    GetGoalTree: '/Extra/GetGoalTree',
    GetAllMapUsers: '/Extra/GetAllMapUsers',
    GetDepartmentGoal: '/Extra/GetDepartmentGoal',
    GetSectorGoal: '/Extra/GetSectorGoal',
    GetActualDataVerificationStatistics: '/Extra/GetActualDataVerificationStatistics',
    GetApprovalNotesByKpiAssignDataDetailId: '/Extra/GetApprovalNotesByKpiAssignDataDetailId',
    GetBalancedScorecardWithResult: '/Extra/GetBalancedScorecardWithResult',
    GetSectorGoalsWithResultByStrategyGoalId: '/Extra/GetSectorGoalsWithResultByStrategyGoalId',
    GetDepartmentGoalsWithResultBySectorGoalId: '/Extra/GetDepartmentGoalsWithResultBySectorGoalId',
    GetKpiAssignWithCalculationPeriod: '/Extra/GetKpiAssignWithCalculationPeriod',
    CheckIfUserAuthorized: '/Extra/CheckIfUserAuthorized',
    ChangeApprovalCycleStepUser: '/Extra/ChangeApprovalCycleStepUser',
    ToggleHierarchy: '/Extra/ToggleHierarchy',
    QuarterSettings: '/Extra/QuarterSettings',
    GetQuarterApprovalCycleAssign: '/Extra/GetQuarterApprovalCycleAssign',
    GetKpiDashboardCardsConfiguration: '/Extra/GetKpiDashboardCardsConfiguration',
    UpdateKpiDashboardCardsConfiguration: '/Extra/UpdateKpiDashboardCardsConfiguration',
    GetSectionsObligationToEnterResults: '/Extra/GetSectionsObligationToEnterResults',
    GetDepartmentsObligationToEnterResults: '/Extra/GetDepartmentsObligationToEnterResults',
    GetSectorsObligationToEnterResults: '/Extra/GetSectorsObligationToEnterResults',
    GetKpiAssignPerformnaceResult: '/Extra/GetKpiAssignPerformnaceResult',
    GetAppVersion: '/Extra/GetAppVersion',
    GetPreviousYearsResultsForKpiAssign: '/Extra/GetPreviousYearsResultsForKpiAssign',
    GetKpiGenericAssignedOnStrategyGoalCount: '/Extra/GetKpiGenericAssignedOnStrategyGoalCount',
    GetKpiGenericAssignedOnStrategyGoal: '/Extra/GetKpiGenericAssignedOnStrategyGoal',
    RemoveKpiGenericAssignOnStrategyGoal: '/Extra/RemoveKpiGenericAssignOnStrategyGoal',
    GetKpiGenericNotAssignedOnStrategyGoalCount: '/Extra/GetKpiGenericNotAssignedOnStrategyGoalCount',
    GetKpiGenericNotAssignedOnStrategyGoal: '/Extra/GetKpiGenericNotAssignedOnStrategyGoal',
    AssignKpiGenericToStrategyGoal: '/Extra/AssignKpiGenericToStrategyGoal',
  },
  ExtraGroup: {
    SavedData: '/SavedData',
    Report: {
      GetKpiReportCount: '/Report/GetKpiReportCount',
      GetKpiReport: '/Report/GetKpiReport',
      GetProjectReportCount: '/Report/GetProjectReportCount',
      GetProjectReport: '/Report/GetProjectReport',
      GetHierarchyKpiAnalysisReportCount: '/Report/GetHierarchyKpiAnalysisReportCount',
      GetHierarchyKpiAnalysisReport: '/Report/GetHierarchyKpiAnalysisReport',
      GetExcellenceKpiReportCount: '/Report/GetExcellenceKpiReportCount',
      GetExcellenceKpiReport: '/Report/GetExcellenceKpiReport',
      GetKpiOnStepReport: '/Report/GetKpiOnStepReport',
    },
    StaticLookup: {
      GetKpiStatusList: '/StaticLookup/GetKpiStatusList',
    }
  },
  NotificationManagement: {
    CreateEmailTemplate: '/NotificationManagement/CreateEmailTemplate',
    UpdateEmailTemplate: '/NotificationManagement/UpdateEmailTemplate',
    GetEmailTemplates: '/NotificationManagement/GetEmailTemplates',
    DeleteEmailTemplate: '/NotificationManagement/DeleteEmailTemplate',
    CreateNotification: '/NotificationManagement/CreateNotification',
    UpdateNotification: '/NotificationManagement/UpdateNotification',
    GetNotifications: '/NotificationManagement/GetNotifications',
    DeleteNotification: '/NotificationManagement/DeleteNotification'
  },
  SupportGroup: {
    SupportTicketConfiguration: {
      GetAll: '/SupportTicketConfiguration/GetAll',
      GetByType: '/SupportTicketConfiguration/GetByType',
      Add: '/SupportTicketConfiguration/Add',
      Update: '/SupportTicketConfiguration/Update'
    },
    SupportTicketFile: {
      GetSupportTicketFiles: '/SupportTicketFile/GetSupportTicketFiles',
      TrashFile: '/SupportTicketFile/TrashFile',
      UploadSupportTicketFile: environment.apiUrl + '/SupportTicketFile/UploadSupportTicketFile'
    },
    SupportTicket: {
      GetCount: '/SupportTicket/GetCount',
      Get: '/SupportTicket/Get',
      GetAll: '/SupportTicket/GetAll',
      GetMySupportTickets: '/SupportTicket/GetMySupportTickets',
      Add: '/SupportTicket/Add',
      MarkAsClosed: '/SupportTicket/MarkAsClosed',
      ChangeStatus: '/SupportTicket/ChangeStatus',
    },
    SupportTicketMessage: {
      GetSupportTicketMessages: '/SupportTicket/GetSupportTicketMessages',
      AddSupportTicketMessage: '/SupportTicket/AddSupportTicketMessage',
    }
  },
  uploadFile: {
    Upload: environment.apiUrl + '/Upload'
  },
  authentication: '/Authentication',
  InnovationGroup: {
    InnovationConfiguration: {
      GetAll: '/InnovationConfiguration/GetAll',
      GetByType: '/InnovationConfiguration/GetByType',
      GetCountByType: '/InnovationConfiguration/GetCountByType',
      Add: '/InnovationConfiguration/Add',
      Update: '/InnovationConfiguration/Update',
      Trash: '/InnovationConfiguration/Trash'
    },
    Suggestion: {
      Get: '/Suggestion/Get',
      GetCount: '/Suggestion/GetCount',
      GetSuggestionsNotLinkedToProjects: '/Suggestion/GetSuggestionsNotLinkedToProjects',
      GetAllSuggestions: '/Suggestion/GetAllSuggestions',
      Add: '/Suggestion/Add',
      Update: '/Suggestion/Update',
      Trash: '/Suggestion/Trash',
      AddRating: '/Suggestion/AddRating'
    },
    InnovationCriteria: {
      Get: '/InnovationCriteria/Get',
      GetAll: '/InnovationCriteria/GetAll',
      GetCount: '/InnovationCriteria/GetCount',
      Add: '/InnovationCriteria/Add',
      Update: '/InnovationCriteria/Update',
      Trash: '/InnovationCriteria/Trash'
    },
    RatingTemplate: {
      Get: '/RatingTemplate/Get',
      GetAll: '/RatingTemplate/GetAll',
      GetById: '/RatingTemplate/GetById',
      GetCount: '/RatingTemplate/GetCount',
      Add: '/RatingTemplate/Add',
      AddCriterias: '/RatingTemplate/AddCriterias',
      Update: '/RatingTemplate/Update',
      Trash: '/RatingTemplate/Trash'
    },
  },
  TeamManagementGroup: {
    Team: {
      Get: '/Team/Get',
      GetTeamsWaitingApprovalCount: '/Team/GetTeamsWaitingApprovalCount',
      GetTeamsWaitingApproval: '/Team/GetTeamsWaitingApproval',
      GetTeamsApprovedCount: '/Team/GetTeamsApprovedCount',
      GetTeamsApproved: '/Team/GetTeamsApproved',
      GetTeamsApprovedList: '/Team/GetTeamsApprovedList',
      Add: '/Team/Add',
      Update: '/Team/Update',
      Approve: '/Team/Approve',
      Reject: '/Team/Reject',
      GetMyTeamsList: '/Team/GetMyTeamsList',
      // Update: '/Team/Update',
      // Trash: '/Team/Trash',
      // GetList: '/Team/GetList',
    },
    TeamSubTask: {
      GetById: '/TeamSubTask/GetById',
      GetCount: '/TeamSubTask/GetCount',
      Get: '/TeamSubTask/Get',
      Add: '/TeamSubTask/Add',
      Update: '/TeamSubTask/Update',
      Trash: '/TeamSubTask/Trash',
    },
    TeamGoal: {
      Add: '/TeamGoal/Add',
      GetTeamGoals: '/TeamGoal/GetTeamGoals',
      GetTeamGoal: '/TeamGoal/GetTeamGoal',
      GetTeamGoalsWaitingApprovalCount: '/TeamGoal/GetTeamGoalsWaitingApprovalCount',
      GetTeamGoalsWaitingApproval: '/TeamGoal/GetTeamGoalsWaitingApproval',
      ApprovalChangeStatus: '/TeamGoal/ApprovalChangeStatus'
    },
    TeamGoalTask: {
      GetTeamGoalTasksWaitingApprovalCount: '/TeamGoalTask/GetTeamGoalTasksWaitingApprovalCount',
      GetTeamGoalTasksWaitingApproval: '/TeamGoalTask/GetTeamGoalTasksWaitingApproval',
      ApprovalChangeStatus: '/TeamGoalTask/ApprovalChangeStatus',
      GetTaskWithFiles: '/TeamGoalTask/GetTaskWithFiles',
      SaveValue: '/TeamGoalTask/SaveValue'
    },
    TeamFile: {
      UploadTeamFile: environment.apiUrl + '/TeamFile/UploadTeamFile',
      TrashFile: '/TeamFile/TrashFile'
    },
    Configuration: {
      TeamPosition: {
        Get: '/TeamPosition/Get',
        GetCount: '/TeamPosition/GetCount',
        GetDropDown: '/TeamPosition/GetDropDown',
        Add: '/TeamPosition/Add',
        Update: '/TeamPosition/Update',
        Trash: '/TeamPosition/Trash'
      },
      Get: '/TeamConfiguration/Get',
      GetCount: '/TeamConfiguration/GetCount',
      GetDropDown: '/TeamConfiguration/GetDropDown',
      Add: '/TeamConfiguration/Add',
      Update: '/TeamConfiguration/Update',
      Trash: '/TeamConfiguration/Trash'
    }
  },
  MysteryShopperGroup: {
    MysteryConfiguration: {
      GetAll: '/MysteryConfiguration/GetAll',
      GetByType: '/MysteryConfiguration/GetByType',
      Add: '/MysteryConfiguration/Add',
      Update: '/MysteryConfiguration/Update'
    },
    MysteryVisit: {
      GetAll: '/MysteryVisit/GetAll',
      GetMysteryVisitById: '/MysteryVisit/GetMysteryVisitById',
      AddMysteryVisit: '/MysteryVisit/AddMysteryVisit',
      UpdateMysteryVisit: '/MysteryVisit/UpdateMysteryVisit',
      AddImprovementOpportunities: '/MysteryVisit/AddImprovementOpportunities',
      AddCorrectionAndDevelopmentPlans: '/MysteryVisit/AddCorrectionAndDevelopmentPlans',
      AddCorrectionAndDevelopmentPlanKpis: '/MysteryVisit/AddCorrectionAndDevelopmentPlanKpis'
    },
    MysteryFile: {
      GetMysteryVisitFiles: '/MysteryFile/GetMysteryVisitFiles',
      UploadMysteryVisitFile: '/MysteryFile/UploadMysteryVisitFile',
      TrashFile: '/MysteryFile/TrashFile'
    }
  },
  SharedGroup: {
    ChangeHistory: {
      GetAllChangesByApprovalCycleAssignId: '/ChangeHistory/GetAllChangesByApprovalCycleAssignId',
      GetChangeHistoryForeignKeyList: '/ChangeHistory/GetChangeHistoryForeignKeyList',
      GetAllChangesByProjectId: '/ChangeHistory/GetAllChangesByProjectId',
      GetRelatedObject: '/ChangeHistory/GetRelatedObject',
      UpdateNewValue: '/ChangeHistory/UpdateNewValue',
      ChangeApproveStatus: '/ChangeHistory/ChangeApproveStatus'
    }
  }
}
