<div class="report-popup">
  <div class="content">
    <h3>{{ message }}</h3>
    <p>{{ pleaseWait }}</p>
    <div class="loader">
      <div class="ball ball-1"></div>
      <div class="ball ball-2"></div>
      <div class="ball ball-3"></div>
    </div>
  </div>
</div>
