import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Translation, checkLanguageAdv } from '../../common/translation';

@Component({
  selector: 'app-loader-popup',
  templateUrl: './loader-popup.component.html',
  styleUrls: ['./loader-popup.component.css']
})
export class LoaderPopupComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  message: string = checkLanguageAdv(this.locale, Translation.thePageWillReloadToApplyTheNewSettings);;
  pleaseWait: string = checkLanguageAdv(this.locale, Translation.pleaseWait);

  ngOnInit() {
  }

}
