import { NotifierService } from 'angular-notifier';
import { User } from "../model/userModel";
import { KpiGeneric } from '../model/kpiGenericModel';
import { Quarter } from 'src/app/dashboards/dashboard-tab/hierarchy-dashboard/hierarchy-dashboard.component';
import { BaseEntity } from '../model/base/base-entity.model';
import { SWOTSection } from '../model/swotSectionModel';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Strategy } from '../model/strategyModel';
import { Project } from '../model/projectModel';
import { ApprovalCycle } from '../model/approvalCycleModel';
import { Department } from '../model/departmentModel';
import { Section } from '../model/sectionModel';
import { Sector } from '../model/sectorModel';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { KpiAssignDataDetails } from '../model/kpiAssignDataDetailsModel';
import { KpiCalculationMethod } from '../model/performance/kpiCalculationMethodModel';
import { HierarchyVersion } from '../model/administration/hierarchyVersionModel';


export class ExtraServices {

  private notifier: NotifierService;

  constructor(notifier: NotifierService) {
    this.notifier = notifier
  }

  public showSpecificNotification(type: string, message: string, id: string): void {
    this.notifier.show({
      id,
      message,
      type
    });
  }


}

import * as XLSX from 'xlsx';
import { Trend } from '../model/trendModel';
import { Classification } from '../model/classificationModel';
export function generateExcelFileToDowbload(columns: string[], data: any[], fileNamePrefix: string = '') {
  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  workbook.Workbook = {};
  workbook.Workbook.Views = [];
  workbook.Workbook.Views.push({ RTL: true });

  // Add the header row
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(worksheet, [columns]);

  // Add the data rows to the worksheet
  XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate a Blob object from the workbook
  const excelBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a file URL from the Blob
  const url = URL.createObjectURL(new Blob([excelBlob], { type: 'application/octet-stream' }));

  // Generate a unique filename
  const timestamp = new Date().getTime();
  const filename = `${fileNamePrefix}_${generateFormattedDate()}_${timestamp}.xlsx`

  // Create a temporary link element and click it to trigger the file download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  // Cleanup: Remove the temporary link and revoke the file URL
  try {
    document.body.removeChild(link);
  } catch (e) {
  }
  URL.revokeObjectURL(url);
}

function generateFormattedDate(): string {
  const currentDate = new Date();

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const month = monthNames[currentDate.getUTCMonth()].substring(0, 3).toUpperCase();
  const day = currentDate.getUTCDate().toString().padStart(2, '0');
  const year = currentDate.getUTCFullYear().toString();

  return `${month}_${day}_${year}`;
}


export const genders: { id: number, name: string, nameArabic: string, code: string }[] = [
  { id: 1, name: 'Female', nameArabic: 'أنثى', code: 'F' },
  { id: 2, name: 'Male', nameArabic: 'ذكر', code: 'M' }
];

export const nationalities: { id: number, name: string, nameArabic: string }[] = [
  { id: 1, name: 'Citizen', nameArabic: 'مواطن' },
  { id: 2, name: 'Expat', nameArabic: 'وافد' }
];

export function getYears(): string[] {
  let years: string[] = [];
  let currentYear = +(new Date).getFullYear();
  for (let year = currentYear + 4; year >= 2015; year--) {
    years.push(year.toString());
  }
  return years;
}

export function openModalContainer(modalService: any, content): any {
  return modalService.open(content, { size: 'lg', windowClass: 'modal-xl', centered: true, backdrop: 'static', keyboard: false });
}
export function openModalContainerWithSize(modalService: any, content, size: string): any {
  size != null ? size = size : size = 'lg';
  return modalService.open(content, { size: size, windowClass: 'modal-xl', centered: true, backdrop: 'static', keyboard: false });
}

export function isAdmin(): boolean {
  let x = getUser().groupList.find(obj => obj.code == 'ADMIN');
  return x != undefined;
}

export function haveGroup(code: string): boolean {
  let x = getUser().groupList.find(obj => obj.code == code);
  return x != undefined;
}

let userKey = 'opldXNlcg==';

export function getUser(): User {
  let userStr = Base64.decode(localStorage.getItem(userKey));
  let user: User = userStr != "" ? JSON.parse(userStr) : null;
  return user;
}

export function setUser(user: User) {
  localStorage.setItem(userKey, Base64.encode(JSON.stringify(user)));
}

export function removeUser() {
  localStorage.removeItem(userKey);
}

let sessionKey = 'vsdgd@&16==';

export function startSession() {
  localStorage.setItem(sessionKey, Base64.encode((Date.now() / 1000 | 0).toString()));
}

export function isSessionExpired(): boolean {
  let nowDate = new Date(Date.now());
  let number = +Base64.decode(localStorage.getItem(sessionKey));
  let sessionDate = new Date(number * 1000);
  let sessionDateValidTill = addMinutes(sessionDate, 64);
  if (sessionDate <= nowDate && nowDate <= sessionDateValidTill) {
    return false;
  } else {
    return true;
  }
}

export function refreshSession() {
  removeSession();
  startSession();
}

export function removeSession() {
  localStorage.removeItem(sessionKey);
}

export function getSession(): any {
  return localStorage.getItem(sessionKey);
}

export function getAllNotSubmittedProjects(): Project[] {
  if (localStorage.getItem('NotSubmittedProject') == null) {
    let initialize: Project[] = [];
    localStorage.setItem('NotSubmittedProject', JSON.stringify(initialize));
  }
  return JSON.parse(localStorage.getItem('NotSubmittedProject'));
}

export function getNotSubmittedProject(id: number): Project {
  if (localStorage.getItem('NotSubmittedProject') == null) {
    let initialize: Project[] = [];
    localStorage.setItem('NotSubmittedProject', JSON.stringify(initialize));
  }
  let projects: Project[] = [];
  projects = JSON.parse(localStorage.getItem('NotSubmittedProject'));
  let foundProject = projects.find(obj => obj.tokenId == id);
  return foundProject;
}

export function addNotSubmittedProject(project: Project) {
  if (localStorage.getItem('NotSubmittedProject') == null) {
    let initialize: Project[] = [];
    localStorage.setItem('NotSubmittedProject', JSON.stringify(initialize));
  }
  let projects: Project[] = [];
  projects = JSON.parse(localStorage.getItem('NotSubmittedProject'));
  projects.push(project);
  localStorage.setItem('NotSubmittedProject', JSON.stringify(projects));
}

export function updateNotSubmittedProject(project: Project) {
  let projects: Project[] = [];
  projects = JSON.parse(localStorage.getItem('NotSubmittedProject'));
  let itemIndex = projects.findIndex(obj => obj.tokenId == project.tokenId);
  projects[itemIndex] = project;
  localStorage.setItem('NotSubmittedProject', JSON.stringify(projects));
}

export function deleteNotSubmittedProject(project: Project) {
  let projects: Project[] = [];
  projects = JSON.parse(localStorage.getItem('NotSubmittedProject'));
  let itemIndex = projects.findIndex(obj => obj.tokenId == project.tokenId);
  projects.splice(itemIndex, 1);
  localStorage.setItem('NotSubmittedProject', JSON.stringify(projects));
}


export function getResponseCode(res): number {
  return JSON.parse(res._body).responseCode;
}

export function canUserEdit(): boolean {
  let user = getUser();
  let canEdit = false;
  if (user != null) {
    let admin = user.groupList.find(obj => obj.code == 'ADMIN');
    if (admin != undefined) {
      canEdit = true;
    }
  }
  return canEdit;
}

export function getMaxHierarchyVersion(list: HierarchyVersion[]): HierarchyVersion | null {
  if (list.length > 0) {
    let maxVersion = list[0];
    list.forEach(obj => {
      if (obj.tillYear > maxVersion.tillYear) {
        maxVersion = obj;
      }
    });
    return maxVersion;
  }
  else {
    return null;
  }
}

const monthArray = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export function getQueryStringFromArray(list: any[], stringParameter: string) {
  if (list.length == 1 && list[0] == undefined) {
    return '';
  }
  let s = '';
  if (list.length > 0) {
    list.forEach((obj, index) => {
      if ((index + 1) == list.length || list.length == 1) {
        s += stringParameter + "=" + obj.id;
      } else {
        s += stringParameter + "=" + obj.id + "&";
      }
    });
  }
  return s;
}

export function extractYears(list: { year: string }[]): string[] {
  let years: string[] = [];
  if (list.length > 0) {
    list.forEach(obj => {
      years.push(obj.year);
    });
  }
  years = years.sort((one, two) => (one > two ? -1 : 1));
  return years;
}

export function getKpiGenericName(model: KpiGeneric, locale): string {
  var name = '';
  if (model != null) {
    if (model.parentId == 0) {
      name = locale == 'ar-AE' ? model.nameArabic : model.name;
    } else {
      if (model.parent.parentId == 0) {
        name = locale == 'ar-AE' ? model.parent.nameArabic + '\n - ' + model.nameArabic : model.parent.name + '\n - ' + model.name;
      } else {
        name = locale == 'ar-AE' ? model.parent.parent.nameArabic + '\n - ' + model.parent.nameArabic + '\n - ' + model.nameArabic : model.parent.parent.name + '\n' + model.parent.name + '\n' + model.name;
      }
    }
  } else {
    name = locale == 'ar-AE' ? 'إسم المؤشر غير موجود' : 'Kpi name not found';
  }
  return name;
}

export function yAxisTickFormatting(value: number) {
  return value.toString() + " %";
}

export function getColorOnValue(value: number): string {
  if (value <= 84) {
    return '#FB3640';
  } else if (value <= 94) {
    // return '#FDE74C';
    return '#fdc500';
  } else if (value <= 101) {
    return '#44AF69';
  } else if (value <= 110) {
    return '#54C6EB';
  } else if (value > 110) {
    return '#8A89C0';
  }
}

export function getSWOTColor(value: number): string {
  if (value < 50) {
    return '#555b6e';
  } else if (value < 70) {
    return '#f9c74f';
  } else if (value >= 70) {
    return '#f94144';
  }
}


export function markObjByUserId(obj: any): any {
  obj['createdBy'] = getUser().id;
  obj['modifiedBy'] = getUser().id;
  obj['trashedBy'] = getUser().id;
  return obj;
}

export function dropDownContainsData(FormControl): boolean {
  if (FormControl.value != null) {
    if (FormControl.value.length > 0) {
      if (FormControl.value[0] == null || FormControl.value[0] == undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function dropDownValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (dropDownContainsData(control)) {
    return null;
  } else {
    return { 'valid': true };
  }
}

export function getDropDownValue(formControl: any): number {
  if (dropDownContainsData(formControl)) {
    return formControl.value[0].id;
  } else {
    return 0;
  }
}

export function getDropDownValueWithKey(formControl: any, list: any[], key: string): string | any {
  if (dropDownContainsData(formControl)) {
    let obj = list.find(item => item.id == formControl.value[0].id)
    return obj[key];
  } else {
    return null;
  }
}

export function newDateValidator(control: AbstractControl): { [key: string]: boolean } | null | { [key: string]: string } {
  if (control.value != null) {
    let date = new Date(control.value);
    if (date.getDay() == 5 || date.getDay() == 6) {
      return { 'invalidValue': 'WEEK_DAYS_ERROR' };
    } else {
      if (control.value === '') {
        return { 'invalidValue': 'REQUIRED_FIELD' };
      } else {
        return null;
      }
    }
  } else {
    return { 'invalidValue': 'REQUIRED_FIELD' };
  }
}

// Angular Version less than 12
export function updateValidators(form: FormGroup, ctrlKey: string, validatorList: any[]) {
  form.controls[ctrlKey].setValidators(validatorList);
  form.controls[ctrlKey].updateValueAndValidity();
}


export function dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value != null) {
    let date = new Date(control.value);
    if (date.getDay() == 6 || date.getDay() == 0) {
      return { 'valid': true };
    } else {
      return null;
    }
  } else {
    return { 'valid': true };
  }
}

export function englishValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const regex = /^[a-zA-Z0-9 ]*$/; // Regular expression for English letters and numbers only
    const isValid = regex.test(value);
    return isValid ? null : { 'english': { value } };
  };
}

export function arabicValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const regex = /^[ء-ي0-9 ]*$/; // Regular expression for Arabic letters and numbers only
    const isValid = regex.test(value);
    return isValid ? null : { 'arabic': { value } };
  };
}

export function symbolsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const regex = /^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,./]*$/; // Regular expression for symbols only
    const isValid = regex.test(value);
    return isValid ? null : { 'symbols': { value } };
  };
}

export function notOnlyNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const regex = /^[0-9]+$/; // Regular expression for numbers only
    const containsNonNumbers = !regex.test(value);
    return containsNonNumbers ? null : { 'notOnlyNumbers': { value } };
  };
}


export function setDropDownValue(formControl: any, list: any[], id: number) {
  formControl.setValue([list.find(obj => obj.id == id)]);
}

export function hierarchyOnValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value != null) {
    return null;
  } else {
    return { 'valid': true };
  }
}

export function getActualCalculationPeriodName(letter: string, locale): string {
  let name = '';
  switch (letter) {
    case 'Q':
      name = locale == 'ar-AE' ? 'ربع سنوي' : 'Quarterly';
      break;
    case 'H':
      name = locale == 'ar-AE' ? 'نصف سنوي' : 'Half-Yearly';
      break;
    case 'Y':
      name = locale == 'ar-AE' ? 'سنوي' : 'Yearly';
      break;
  }
  return name;
}

export function getUserAuth() {
  let user = getUser();
  let level = '';

  if (user.sectionId != 0) {
    level = 'section';
  }

  if (user.departmentId != 0 && user.sectionId == 0) {
    level = 'department';
  }

  if (user.departmentId == 0 && user.sectionId == 0) {
    level = 'sector';
  }

  let checkIfSPP = user.groupList.find(obj => obj.code == 'SPP');
  if (checkIfSPP != undefined) {
    level = 'sector';
  }

  let checkIfAdmin = user.groupList.find(obj => obj.code == 'ADMIN');
  if (checkIfAdmin != undefined) {
    level = '';
  }

  let sectionId = user.sectionId;
  let departmentId = user.departmentId;
  let sectorId = user.sectorId;

  return { level: level, sectorId: sectorId, departmentId: departmentId, sectionId: sectionId };
}

export function pickRandomCardColor(): string {
  let colors = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93',];
  const randomElement = colors[Math.floor(Math.random() * colors.length)];
  return randomElement;
}

export function exportDropDownSettings(dropDownCase, locale) {
  if (dropDownCase == 'single') {
    return {
      singleSelection: true,
      idField: 'id',
      searchPlaceholderText: locale == 'ar-AE' ? 'إبحث' : 'Search',
      noDataAvailablePlaceholderText: locale == 'ar-AE' ? 'لا يوجد بيانات' : 'No items',
      textField: locale == 'ar-AE' ? 'nameArabic' : 'name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  } else if (dropDownCase == 'multi') {
    return {
      singleSelection: false,
      idField: 'id',
      searchPlaceholderText: locale == 'ar-AE' ? 'إبحث' : 'Search',
      noDataAvailablePlaceholderText: locale == 'ar-AE' ? 'لا يوجد بيانات' : 'No items',
      textField: locale == 'ar-AE' ? 'nameArabic' : 'name',
      selectAllText: locale == 'ar-AE' ? 'تحديد الكل' : 'Select All',
      unSelectAllText: locale == 'ar-AE' ? 'إلغاء تحديد الكل' : 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}

export function getOnDetailValues(): any {
  let onDetail: any = null;
  if (!isAdmin()) {
    let user = getUser();
    let on = '';
    let onId = 0;
    if (user.on != null && user.onId != null) {
      onDetail = { on: capitalizeFirstLetter(user.on), onId: user.onId };
    } else {
      if (user.sectionId != 0) {
        on = 'Section';
        onId = user.sectionId;
      } else if (user.departmentId != 0) {
        on = 'Department';
        onId = user.departmentId;
      } else if (user.sectorId != 0) {
        on = 'Sector';
        onId = user.sectorId;
      }
      onDetail = { on: on, onId: onId };
    }
  } else {
    onDetail = null;
  }
  return onDetail;
}

function capitalizeFirstLetter(word: string): string {
  return word.replace(/^./, (char) => char.toUpperCase());
}

export function getOnDetailValueByUser(user: User): any {
  let onDetail: any = null;
  let on = '';
  let onId = 0;
  if (user.sectionId != 0) {
    on = 'Section';
    onId = user.sectionId;
  } else if (user.departmentId != 0) {
    on = 'Department';
    onId = user.departmentId;
  } else if (user.sectorId != 0) {
    on = 'Sector';
    onId = user.sectorId;
  }
  onDetail = { on: on, onId: onId };
  return onDetail;
}


export function getProjectCurrentStatus(status: string) {

  let result = null;
  switch (status) {
    case 'NOT_SENT':
      result = { name: 'Not SENT', nameArabic: 'لم يرسل', color: '#bdb2ff' };
      break;
    case 'NOT_STARTED':
      result = { name: 'Not Started', nameArabic: 'لم يبدأ', color: '#ced4da' };
      break;
    case 'IN_PROGRESS':
      result = { name: 'In Progress', nameArabic: 'قيد الإنجاز', color: '#f9c74f' };
      break;
    case 'FINISHED_EARLY':
      result = { name: 'Finished Early', nameArabic: 'منجز قبل الوقت', color: '#0077b6' };
      break;
    case 'FINISHED_ON_TIME':
      result = { name: 'Finished on Time', nameArabic: 'منجز على الوقت', color: '#2b9348' };
      break;
    case 'FINISHED_WITH_DELAY':
      result = { name: 'Finished with Delay', nameArabic: 'منجز بعد الوقت', color: '#ef233c' };
      break;
  }
  return result;
}

export function getApprovalCycleClassAndName(returnCase: string, approvalCycle: ApprovalCycle, locale: string): string {
  switch (returnCase) {
    case 'class':
      return 'btn approval-cycle-' + approvalCycle.code;
    case 'name':
      return locale == 'ar-AE' ? approvalCycle.nameArabic : approvalCycle.name;
  }
}

export function formatDateToString(date, locale: string) {
  return date != null ?
    getDateYYYYMMDD(new Date(date)) != '1-01-01' ?
      getDateYYYYMMDD(new Date(date))
      : (locale == 'ar-AE' ? 'لا يوجد تاريخ' : 'No Date')
    : (locale == 'ar-AE' ? 'لا يوجد تاريخ' : 'No Date');
}

export function findItemIndex(list: any[], id: number): number {
  let index = -1;
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      index = i;
    }
  }
  return index;
}

export const quarters: Quarter[] = [
  { id: 1, name: 'First Quarter', nameArabic: 'الربع الأول', months: ['JAN', 'FEB', 'MAR'], code: 'Q1' },
  { id: 2, name: 'Second Quarter', nameArabic: 'الربع الثاني', months: ['APR', 'MAY', 'JUN'], code: 'Q2' },
  { id: 3, name: 'Third Quarter', nameArabic: 'الربع الثالث', months: ['JUL', 'AUG', 'SEP'], code: 'Q3' },
  { id: 4, name: 'Fourth Quarter', nameArabic: 'الربع الرابع', months: ['OCT', 'NOV', 'DEC'], code: 'Q4' },
];

export const swotTypes: SwotType[] =
  [
    { id: 1, name: "Strengths", nameArabic: "نقاط القوة", color: "#a8d70d", swotSections: [], single: "Strength", singleArabic: "قوة" },
    { id: 2, name: "Weaknesses", nameArabic: "نقاط الضعف", color: "#f8423e", swotSections: [], single: "Weakness", singleArabic: "ضعف" },
    { id: 3, name: "Opportunities", nameArabic: "الفرص", color: "#feb52a", swotSections: [], single: "Opportunity", singleArabic: "فرصة" },
    { id: 4, name: "Threats", nameArabic: "التهديدات", color: "#2ac5fe", swotSections: [], single: "Threat", singleArabic: "خطر" }
  ];

export class SwotType {
  id: number;
  name: string;
  nameArabic: string;
  color: string;
  swotSections: SWOTSection[];
  single: string;
  singleArabic: string;
}

export function getCurrentMonths() {
  let date = new Date();
  let numberMonth = date.getMonth() + 1;
  let dayOftheMonth = date.getDate();
  let selectedQuarters = [];

  if (numberMonth >= 12) {
    if (dayOftheMonth > 20) {
      selectedQuarters = quarters;
    } else {
      selectedQuarters = [quarters[0], quarters[1], quarters[2]];
    }
  } else if (numberMonth >= 9) {
    if (numberMonth == 9 && dayOftheMonth < 20) {
      selectedQuarters = [quarters[0], quarters[1]];
    } else {
      selectedQuarters = [quarters[0], quarters[1], quarters[2]];
    }
  } else if (numberMonth >= 6) {
    if (numberMonth == 6 && dayOftheMonth < 20) {
      selectedQuarters = [quarters[0]];
    } else {
      selectedQuarters = [quarters[0], quarters[1]];
    }
  } else if (numberMonth < 6) {
    selectedQuarters = [quarters[0]];
  }

  return selectedQuarters
}

export function getCurrentMonthsTweeked(quarterSettings: any[]) {
  let date = new Date();
  let numberMonth = date.getMonth() + 1;
  let dayOftheMonth = date.getDate();
  let selectedQuarters = [];

  if (numberMonth >= 12) {
    if (dayOftheMonth > 20) {
      selectedQuarters = mapQuarters(quarterSettings);
    } else {
      selectedQuarters = mapQuarters(quarterSettings);
    }
  } else if (numberMonth >= 9) {
    if (numberMonth == 9 && dayOftheMonth < 20) {
      selectedQuarters = mapQuarters(quarterSettings);
    } else {
      selectedQuarters = mapQuarters(quarterSettings);
    }
  } else if (numberMonth >= 6) {
    if (numberMonth == 6 && dayOftheMonth < 20) {
      selectedQuarters = mapQuarters(quarterSettings);
    } else {
      selectedQuarters = mapQuarters(quarterSettings);
    }
  } else if (numberMonth < 6) {
    selectedQuarters = mapQuarters(quarterSettings);
  }

  return selectedQuarters
}

export function mapQuarters(quarterSettings: any[]): any[] {
  let quartersNew: any[] = [];
  quarterSettings.forEach((obj, index) => {
    if (obj.IsActive) {
      quartersNew.push(quarters[index])
    }
  });
  return quartersNew;
}

export function getCurrentQuarter() {
  let date = new Date();
  let numberMonth = date.getMonth() + 1;
  let dayOftheMonth = date.getDate();
  let selectedQuarter = null;

  if (numberMonth >= 12) {
    if (dayOftheMonth > 20) {
      selectedQuarter = quarters[3];
    } else {
      selectedQuarter = quarters[2];
    }
  } else if (numberMonth >= 9) {
    if (numberMonth == 9 && dayOftheMonth < 20) {
      selectedQuarter = quarters[1];
    } else {
      selectedQuarter = quarters[2];
    }
  } else if (numberMonth >= 6) {
    if (numberMonth == 6 && dayOftheMonth < 20) {
      selectedQuarter = quarters[0];
    } else {
      selectedQuarter = quarters[1];
    }
  } else if (numberMonth < 6) {
    selectedQuarter = quarters[0];
  }

  return selectedQuarter;
}


export function getMonths(year: string, selectedQuarters: Quarter[]): string[] {
  let months: string[] = [];
  let monthSelected = getMonthSelected(selectedQuarters);
  monthSelected.forEach((month, i) => {
    months.push(month + "-" + year);
  })
  return months;
}

export function getMonthSelected(selectedQuarters: Quarter[]): string[] {
  let month: string[] = [];
  selectedQuarters.forEach(obj => {
    let q = quarters.find(qS => qS.id == obj.id);
    month = month.concat(q.months);
  });

  return month;
}

export function getQueryStringMonthYear(list: any[]) {
  let s = '';
  if (list.length > 0) {
    list.forEach((obj, index) => {
      if ((index + 1) == list.length || list.length == 1) {
        s += "Month=" + obj;
      } else {
        s += "Month=" + obj + "&";
      }
    });
  }
  return s;
}

export const noteTypes = [
  "KPI_REJECTION",
  "ACTUAL_VALUE_NOT_ACHIEVED",
  "PROJECT_APPROVAL",
  "PROJECT_TASK_APPROVAL",
  "PROJECT_IMPACT_ACTUAL_VALUE",
  "PROJECT_COMPLETION",
  "GOAL_TEAMS",
  "GOAL_TEAM_TASK"
];

export function getSendToPositions(): SendToPosition[] {

  let list: SendToPosition[] = [];
  let p1 = new SendToPosition();
  let p2 = new SendToPosition();
  let p3 = new SendToPosition();
  let p4 = new SendToPosition();

  p1.id = 1;
  p2.id = 2;
  p3.id = 3;
  p4.id = 4;

  p1.name = 'Section Manger';
  p2.name = 'Department Manger';
  p3.name = 'Sector Manger';
  p4.name = 'Direct Manger';

  p1.nameArabic = 'مدير قسم';
  p2.nameArabic = 'مدير إدارة';
  p3.nameArabic = 'مدير قطاع';
  p4.nameArabic = 'المدير المباشر';

  p1.code = 'SECTION_MANAGER';
  p2.code = 'DEPARTMENT_MANAGER';
  p3.code = 'SECTOR_MANAGER';
  p4.code = 'DIRECT_MANAGER';

  list.push(p1);
  list.push(p2);
  list.push(p3);
  list.push(p4);

  return list;
}
export const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
export const daysOfTheMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const projectConfigurationTypes = ["PROJECT_TYPE", "PROJECT_CLASSIFICATION", "PROJECT_STATUS", "PROJECT_SOURCE",
  "PROJECT_MEMBER_ROLE", "PROJECT_EXTERNAL_PARTNER", "IMPACT_TYPE", "IMPACT_AFFECTED_PROCESS", "IMPACT_MEASUREMENT_METHOD", "IMPACT_TARGET_AUDIENCE",
  "FS_RISK_TYPE", "FS_ALTERNATIVE_SOLUTION_TYPE", "FS_BEST_PRACTICE_TYPE", "FS_PESTEL_TYPE"];
export const hierarchyLevels: { id: number, name: string, nameArabic: string }[] = [{ id: 1, name: 'Sector', nameArabic: 'قطاع' }, { id: 2, name: 'Department', nameArabic: 'إدارة' }, { id: 3, name: 'Section', nameArabic: 'قسم' }];

export class SendToPosition extends BaseEntity {
  constructor() {
    super();
  }
  code: string;
}

export function getCurrentStrategy(strategies): Strategy {
  let currentStrategy = null;
  let maxStrategy = null;
  strategies.forEach(obj => {
    let yearStart = obj.startDate.substring(0, 4);
    let yearEnd = obj.endDate.substring(0, 4);
    let currentYear = (new Date()).getFullYear();

    if (maxStrategy == null) {
      maxStrategy = obj;
    } else {
      let oldMaxYearEnd = +maxStrategy.endDate.substring(0, 4);
      let newMaxYearEnd = yearEnd;
      if (newMaxYearEnd > oldMaxYearEnd) {
        maxStrategy = obj;
      }
    }

    if (+currentYear >= yearStart && currentYear <= yearEnd) {
      currentStrategy = obj;
    }

  });
  return currentStrategy != null ? currentStrategy : maxStrategy;
}

export function getStrategyByYear(strategies, year): Strategy {
  let strategy = null;
  strategies.forEach(obj => {
    let yearStart = obj.startDate.substring(0, 4);
    let yearEnd = obj.endDate.substring(0, 4);

    if (+year >= +yearStart && year <= +yearEnd) {
      strategy = obj;
    }
  });
  return strategy;
}


export function getDateYYYYMMDD(date: Date): string {
  var mm = date.getMonth() + 1;
  var dd = date.getDate();

  return date.getFullYear() + "-" + (mm > 9 ? '' : '0') + mm + "-" +
    (dd > 9 ? '' : '0') + dd;
}

export function generateToken(id: number, viewCase: string): string {
  let v1 = (Date.now() / 1000 | 0) * id * calculateStringValue(viewCase);
  return Base64.encode(v1.toString());
}

export function calculateStringValue(str: string): number {
  str = str.toLowerCase();
  var value = 0;
  for (var i = 0; i < str.length; i++) {
    value += str.indexOf(str[i]) + 1;
  }

  return value;
}

export function calculateYearlyValues(column: string, quarters: any[], caluclationMethod: KpiCalculationMethod) {
  let filteredQuarters = quarters.filter(obj => obj.willBeMeasured == 1);
  let value = 0;
  switch (caluclationMethod.code) {
    case 'CUMULATIVE':
      let q4 = filteredQuarters.find(obj => obj.monthYear.substring(0, 3) == 'DEC');
      let q3 = filteredQuarters.find(obj => obj.monthYear.substring(0, 3) == 'SEP');
      let q2 = filteredQuarters.find(obj => obj.monthYear.substring(0, 3) == 'JUN');
      let q1 = filteredQuarters.find(obj => obj.monthYear.substring(0, 3) == 'MAR');

      value = q4 != undefined ? q4[column] : (q3 != undefined ? q3[column] : (q2 != undefined ? q2[column] : (q1 != undefined ? q1[column] : 0)));
      break;
    case 'AVG':
      filteredQuarters.forEach(q => {
        value += q[column];
      });
      value = value / filteredQuarters.length;
      break;
    case 'SUM':
      filteredQuarters.forEach(q => {
        value += q[column];
      });
      break;
  }
  return value == null ? 0 : +value.toFixed(2);
}


export function calculateCardColums(itemNumber: number, totalCardsCount: number, numberOfItemsInRow: number): number {
  // if (numberOfItemsInRow > 0) {
  //   return Math.ceil(totalCardsCount / numberOfItemsInRow);
  // } else {
  //   let maxNumberInRow = 3;
  //   let column = Math.ceil(totalCardsCount / maxNumberInRow);
  //   let actualNumberOfFullRows = Math.floor(totalCardsCount / maxNumberInRow);
  //   let numberOfItemsInLastRow = totalCardsCount - (actualNumberOfFullRows * maxNumberInRow);
  //   let countOfItemsInAllFullRows = (totalCardsCount - numberOfItemsInLastRow) * maxNumberInRow;
  //   if ((itemNumber * maxNumberInRow) <= countOfItemsInAllFullRows) {
  //     return column;
  //   } else {
  //     return 12 / numberOfItemsInLastRow;
  //   }
  // }
  return 3;
}


export function isTokenValid(token: string, id: number, viewCase: string): boolean {
  let number = +Base64.decode(token);
  number = number / id;
  number = number / calculateStringValue(viewCase);
  let nowDate = new Date(Date.now());
  let tokenDate = new Date(number * 1000);
  let tokenDateValidTill = addMinutes(tokenDate, 10);
  //------------------------------------------------------------
  if (tokenDate <= nowDate && nowDate <= tokenDateValidTill) {
    return true;
  } else {
    return false;
  }
  // return true;
}

export function checkNumberValue(number): number {
  if (isNaN(number)) {
    number = 0.0;
  } else if (number == "NaN" || number == "Infinity") {
    number = 0.0;
  }
  return +number.toFixed(2);
}

function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function notAvailable(item: any): any {
  if (item != undefined) {
    return item;
  } else {
    return { name: 'Not Available', nameArabic: 'لا يوجد' };
  }
}

var Base64 = {

  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  encode: function (input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {

      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output = output +
        this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

    }

    return output;
  },

  // public method for decoding
  decode: function (input) {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;

    if (input != null) {
      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");


      while (i < input.length) {

        enc1 = this._keyStr.indexOf(input.charAt(i++));
        enc2 = this._keyStr.indexOf(input.charAt(i++));
        enc3 = this._keyStr.indexOf(input.charAt(i++));
        enc4 = this._keyStr.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output = output + String.fromCharCode(chr1);

        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }

      }
    }

    output = Base64._utf8_decode(output);

    return output;

  },

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";

    for (var n = 0; n < string.length; n++) {

      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      }
      else if ((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    var string = "";
    var i = 0;
    var c = 0, c3 = 0, c2 = 0;

    while (i < utftext.length) {

      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      }
      else if ((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      }
      else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }
    return string;
  }

}

export function searchByName(searchFilter: string, copyList: any[], locale: string): any[] {
  if (searchFilter == '') {
    return copyList;
  } else {
    if (locale == 'ar-AE') {
      return copyList.filter(obj => obj.nameArabic.toLowerCase().includes(searchFilter.toLowerCase()));
    } else {
      return copyList.filter(obj => obj.name.toLowerCase().includes(searchFilter.toLowerCase()));
    }
  }
}

export function omitSpecialChar(event) {
  var k;
  k = event.charCode;
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getFromText(onDetailsText: string, locale: string, sectors: Sector[], departments: Department[], sections: Section[]): string {
  let onDetails = JSON.parse(onDetailsText);
  switch (onDetails.on) {
    case 'Sector':
      let sector = sectors.find(obj => obj.id == onDetails.onId);
      if (sector == undefined) {
        return locale == 'ar-AE' ? 'لا يوجد' : 'Not Available';
      }
      return locale == 'ar-AE' ? sector.nameArabic : sector.name;
    case 'Department':
      let department = departments.find(obj => obj.id == onDetails.onId);
      if (department == undefined) {
        return locale == 'ar-AE' ? 'لا يوجد' : 'Not Available';
      }
      return locale == 'ar-AE' ? department.nameArabic : department.name;
    case 'Section':
      let section = sections.find(obj => obj.id == onDetails.onId);
      if (section == undefined) {
        return locale == 'ar-AE' ? 'لا يوجد' : 'Not Available';
      }
      return locale == 'ar-AE' ? section.nameArabic : section.name;
    default:
      return locale == 'ar-AE' ? 'لا يوجد' : 'Not Available';
  }
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function uploadFile(file: any, uploadUrl: string, http: HttpClient) {
  const formData = new FormData();
  formData.append(file.name, file);

  const uploadReq = new HttpRequest('POST', environment.apiUrl + uploadUrl, formData, {
    reportProgress: true
  });

  http.request(uploadReq).subscribe(event => {
    if (event.type === HttpEventType.Response) {

    }
  });
}

export function getTrendViewDetails(trend: Trend): TrendViewDetails {
  let trendViewDetails = null;
  switch (trend.code) {
    case 'DEC':
      trendViewDetails = { class: 'label bg-danger', icon: 'mdi mdi-trending-down' };
      break;
    case 'INCR':
      trendViewDetails = { class: 'label bg-success', icon: 'mdi mdi-trending-up' };
      break;
    case 'EQUAL':
      trendViewDetails = { class: 'label bg-info', icon: 'mdi mdi-equal' };
      break;
    default:
      trendViewDetails = { class: 'label bg-secondary', icon: '' };
      break;
  }
  return trendViewDetails;
}

export function getClassificationDetails(classification: Classification): ClassificationDetails {
  let classificationDetails = null;
  switch (classification.code) {
    case 'OPT':
      classificationDetails = { class: 'label bg-success' };
      break;
    case 'STG':
      classificationDetails = { class: 'label bg-warning' };
      break;
    case 'M':
      classificationDetails = { class: 'label bg-primary' };
      break;
    default:
      classificationDetails = { class: 'label bg-secondary' };
      break;
  }
  return classificationDetails;
}

interface TrendViewDetails {
  class: string;
  icon: string;
}

interface ClassificationDetails {
  class: string;
}
